import React from 'react';
import { useState } from 'react';
import GIFCard from './gifCard';
import LinearColor from './LinearBufferChart';


function SendAudio(props) {
  const [response, setResponse] = useState([])
  const [showProgressBar, setShowProgressBar] = useState(0)
  const [showGIF, setShowGIF] = useState(0)


  function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const requestOptions = {
    method: 'POST',
    // headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': 'https://fsds-voice-emotion.herokuapp.com' },
    responseType: 'text',
    body: props.props.file
  };

  const communicateBackEnd = async () => {
    setShowProgressBar(1)
    setResponse([])
    const resp = await fetch("https://fsds-voice-emotion.herokuapp.com/send_audio", requestOptions);
    const data = await resp.json();
    console.log(data.class_predicted)
    setResponse(data)
    setShowProgressBar(0)
    setShowGIF(1)


  };

  

  return (
    <div>
      <header className="App-button-header">

        <img src={props.props.isRecording ? "../assets/cassette2.gif" : "../assets/still_cassette.jpeg"} style={{ width: '16.5em', }} alt="cassete" />
        <br />
        <button disabled={props.props.isRecording} onClick={() => communicateBackEnd(props)}>{props.props.isRecording ? "Recording" : "Send"}</button>
        <br />
        {/* <button>{response.length === 0 ? "Waiting" : capitalize(response.class_predicted)}</button> */}
        
        {response.length == 0 && showProgressBar? <LinearColor /> : <button>{response.class_predicted && capitalize(response.class_predicted)}</button>}
        {showGIF ? <GIFCard class={response.class_predicted}/> : undefined}
      </header>
    </div>
  );
}

export default SendAudio;