import React from 'react';
import './App.css';
import MicRecorder from 'mic-recorder-to-mp3';
import SendAudio from './components/SendAudio';
import { capitalize } from '@mui/material';

const Mp3Recorder = new MicRecorder({ bitRate: 128 });



class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isRecording: false,
      blobURL: '',
      file: {},
      isBlocked: false,
    };
  }



  start = () => {
    if (this.state.isBlocked) {
      console.log('Permission Denied');
    } else {
      Mp3Recorder
        .start()
        .then(() => {
          this.setState({ isRecording: true });
        }).catch((e) => console.error(e));
    }
  };

  stop = () => {
    Mp3Recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob)
        const file = new File(buffer, 'emotionDetection.mp3', {
          type: blob.type,
          lastModified: Date.now()
        });
        console.log(file)
        this.setState({ file, isRecording: false });
        this.setState({ blobURL, isRecording: false });
      }).catch((e) => console.log(e));
  };

  componentDidMount() {

    let pingServer = fetch("https://fsds-voice-emotion.herokuapp.com")
    console.log(pingServer)
    navigator.getUserMedia({ audio: true },
      () => {
        console.log('Permission Granted');
        this.setState({ isBlocked: false });
      },
      () => {
        console.log('Permission Denied');
        this.setState({ isBlocked: true })
      },
    );
  }


  render() {
    return (
      <div className="App">
        {/* <header className="App-header">
          <p>Voice Emotion Detection</p>
        </header> */}
        <body className="App-body">
          <h1>{capitalize("Emotion Detectron v.1")}</h1>

          <button onClick={this.state.isRecording ? this.stop : this.start}>{this.state.isRecording ? "Stop" : "Start"}</button>
          <SendAudio props={this.state} />
          <audio src={this.state.blobURL} controls="controls" />
        </body>
      </div>
    );
  }
}

export default App;