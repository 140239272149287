import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useState, useEffect } from "react"




export default function GIFCard(props) {

    function check(props){
        console.log("here")
        console.log(props)
    }


    // const [imageURL, setImageURL] = useState('https://media.giphy.com/media/3o7bu3XilJ5BOiSGic/giphy.gif')
    // setImageURL(get_gif_url(props.class))

    // useEffect(() => {
    //     const get_gif_url = async (query) => {
    //         const resp = await fetch(
    //             "https://api.giphy.com/v1/gifs/search?api_key=GZcw4CwlATxROa03HnsduetFwtEP1ntP&q=" + query + "&limit=1&offset=0&rating=r&lang=en"
    //         );
    //         const data = await resp.json();
    //         console.log(data)
    //         const result = data["data"][0].images.original.url
    //         console.log("what: " +result)
    //         setImageURL(result)
    //         return result
    //     }


    //     return () => {
    //         get_gif_url(props.class)
    //     }
    // }, [props.class])


    return (
        <>
            <Card>
                <>
                    <CardMedia

                        component="img"
                        alt={''}
                        img src={`../assets/${props.class}.gif`}
                        title='em'
                    />
                    {/* <CardContent>

                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                        ></Typography>
                    </CardContent> */}
                </>
            </Card>


        </>
        // <Card >
        //     <CardHeader

        //         // action={

        //         // }
        //         title="Shrimp and Chorizo Paella"
        //         subheader="September 14, 2016"
        //     />
        //     <CardMedia
        //         height="194"
        //         image='https://media.giphy.com/media/iGLJ0SlNsW2X1e6a7n/giphy.gif'
        //         alt="Angry"
        //     />


        // </Card>

    );
}